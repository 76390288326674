/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&family=Carter+One&family=Montserrat:wght@300;400;500;600;700;800;900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

@import "~swiper/css";
@import "~swiper/css/pagination";

@import "~@angular/material/theming";
@include mat-core();

$my-app-primary: mat-palette($mat-deep-purple);
$my-app-accent: mat-palette($mat-purple);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);

@include angular-material-theme($my-app-theme);

:root {
  --darkBlue: #a7cdf1;
  --blue: #6eacda;
  --grey: #d2d2d4;
  --ligthGrey: #e4e5ea;

  --green: #43a547;
  --yellow: #e4aa64;
  --red: #f23a2f;
  --gold: #fbc02d;

  --greyField: #cdcbcb;
  --greyBackground: #e4e4e4;
  --dropZone: #e8e0e0;

  --grey: #fafafa;
  --darkViolet: #673ab7;
  --lightViolet: #9c27b0;

  --pink: #e000d2;
}

* {
  font-family: "Open Sans", sans-serif !important;
  scroll-behavior: smooth;
  font-size: 16px;
}

.material-icons {
  font-family: "Material Icons" !important;
}

body {
  margin: 0px;
  padding: 0px;
}

html,
body {
  background: transparent;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.page {
  background-color: #f7f7f8;
  padding: 25px 50px;
  .head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    border-bottom: 1px solid #1d1d1d;
    h1 {
      font-weight: 600;
      margin-bottom: 15px;
    }
    button {
      width: 25% !important;
    }

    .cta {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 8px;
      p {
        white-space: nowrap;
        margin: 0;
      }
      button {
        height: 10%;
        line-height: 24px;
        padding: 10px 20px;
        width: 100% !important;
      }
    }
  }
  button {
    &.delete {
      color: var(--red);
    }
  }
  button {
    .mat-icon {
      transition: none;
      margin-bottom: 2px;
    }
    &.delete {
      color: var(--red);
    }
  }

  .addElement {
    width: 25%;
    background-color: rgba(0, 0, 0, 0.04);
    height: fit-content;
    height: -moz-fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .headElement {
      h2 {
        display: flex;
        padding: 15px;
        font-weight: bold;
      }
    }
    .fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      mat-form-field {
        width: 70%;
      }
    }
    .action {
      display: flex;
      padding: 15px;
    }
  }
}

.custom-dropzone {
  border: none !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  .label {
    font-style: italic;
  }
}

ngx-dropzone-image-preview {
  img {
    max-width: 100%;
  }
}

// Materials

.mat-checkbox-label {
  white-space: pre-wrap;
}

.mat-progress-bar {
  z-index: 1000;
  position: fixed !important;
  left: 0;
  top: 0;
}

.material-icons {
  transition: 0.3s all ease;
  &:hover {
    opacity: 0.7;
  }
}

.mat-snack-bar-container {
  &.success-snackbar {
    background: var(--green);
    color: #fff;
    button {
      background-color: var(--green);
      color: #fff;
      border: none;
    }
  }
  &.warning-snackbar {
    background: var(--yellow);
    color: #fff;
    button {
      background-color: var(--yellow);
      color: #fff;
      border: none;
    }
  }
  &.error-snackbar {
    background: var(--red);
    color: #fff;
    button {
      background-color: var(--red);
      color: #fff;
      border: none;
    }
  }
}

.mat-raised-button {
  padding: 5px 30px;
  width: 100%;
  transition: 0.3s opacity ease;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background: var(--green);
    color: #fff;
    opacity: 0.3;
  }
  &.success {
    background: var(--green);
    color: #fff;
  }
  &.pink {
    background: var(--pink);
    color: #fff;
  }
  &.blue {
    background: var(--blue);
    color: #fff;
  }
  &.error {
    background: var(--red);
    color: #fff;
  }
}

.mat-button {
  transition: 0.3s opacity ease;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    background: var(--green);
    color: #fff;
    opacity: 0.3;
  }
  &.success {
    background: var(--green);
    color: #fff;
  }
  &.pink {
    background: var(--pink);
    color: #fff;
  }
  &.blue {
    background: var(--blue);
    color: #fff;
  }
  &.error {
    background: var(--red);
    color: #fff;
  }
}

.mat-icon-button {
  transition: 0.3s opacity ease;
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    color: var(--green);
    opacity: 0.3;
  }
  &.success {
    color: var(--green);
  }
  &.pink {
    color: var(--pink);
  }
  &.blue {
    color: var(--blue);
  }
  &.error {
    color: var(--red);
  }
}

mat-form-field {
  width: 30%;
  .mat-input-element {
    height: 22px;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  padding: -5px;
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-list-base .mat-list-item .mat-list-item-content {
  font-size: 14px;
  font-weight: 400;
}

.mat-divider {
  margin: 20px auto !important;
  border-top-color: var(--darkViolet);
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  button {
    display: none;
  }
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-tooltip {
  font-size: 14px;
}

.mat-table {
  width: 100%;
  .mat-header-cell {
    text-align: center !important;
    font-size: 14px;
    color: #1d1d1d;
    font-weight: 600;
  }
  .mat-cell {
    text-align: center !important;
    color: #000;
    .mat-icon {
      vertical-align: middle;
      margin-bottom: 2px;
    }
  }
  .mat-row:nth-child(n + 1) {
    background-color: #f2f2f2;
  }
  .mat-row:not(:nth-child(2n + 1)) {
    background-color: var(--ligthGrey);
  }
}

.mat-paginator {
  color: #1d1d1d;
  .mat-icon-button {
    color: #1d1d1d !important;
    &.mat-button-disabled {
      opacity: 0.5 !important;
    }
  }
  .mat-select-value-text span {
    color: #1d1d1d;
  }
}

.mat-paginator-navigation-next:not(.mat-button-disabled),
.mat-paginator-navigation-previous:not(.mat-button-disabled) {
  color: var(--darkViolet);
}

.mat-dialog-container {
  min-width: 50vw;
  max-width: 900px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mat-option {
  &.Helvetica {
    font-family: "Helvetica" !important;
    .mat-option-text {
      font-family: "Helvetica" !important;
    }
  }
  &.Alexandria {
    font-family: "Alexandria" !important;
    .mat-option-text {
      font-family: "Alexandria" !important;
    }
  }
  &.Arial {
    font-family: "Arial" !important;
    .mat-option-text {
      font-family: "Arial" !important;
    }
  }
  &.Verdana {
    font-family: "Verdana" !important;
    .mat-option-text {
      font-family: "Verdana" !important;
    }
  }
  &.Lato {
    font-family: "Lato" !important;
    .mat-option-text {
      font-family: "Lato" !important;
    }
  }
  &.Open {
    font-family: "Open Sans" !important;
    .mat-option-text {
      font-family: "Open Sans" !important;
    }
  }
  &.Roboto {
    font-family: "Roboto" !important;
    .mat-option-text {
      font-family: "Roboto" !important;
    }
  }
  &.Oswald {
    font-family: "Oswald" !important;
    .mat-option-text {
      font-family: "Oswald" !important;
    }
  }
  &.Nerko {
    font-family: "Nerko One" !important;
    .mat-option-text {
      font-family: "Nerko One" !important;
    }
  }
}

#preview-img-edit-question-light {
  ngx-dropzone-remove-badge {
    opacity: 1;
  }
}

@media (max-width: 680px) {
  .page {
    padding: 25px 5%;
  }
}

.plan {
  .description {
    margin-bottom: 20px;
    ul {
      padding-left: 20px !important;
      li {
        font-size: 14px !important;
        padding-left: 8px;
        strong,
        p {
          font-size: 14px !important;
        }
      }
      li::marker {
        content: "✓" !important;
      }
    }

    h3,
    h2 {
      text-align: center;
      font-family: "Nunito" !important;
      font-weight: 700 !important;
    }

    h3 {
      font-size: 18px !important;
    }
    h2 {
      font-size: 32px !important;
    }
  }
  .subtitle {
    p {
      padding: 10px;
      margin: 0;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  p {
    padding: 0 10px;
  }
}
